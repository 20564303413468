<template>
  <div class="pointRankList">
    <!-- <count-to
      :start-val="0"
      :end-val="pointsData.totlePointCount"
      :duration="1000"
      class="card-panel-num"
    /> -->
    <div class="header">
      <img src="./img/pointRank.png" alt="" />
      <!-- <div class="nav">
        <div
          :class="['navItem', { select: curTab.value === item.value }]"
          v-for="(item, index) in tabs"
          :key="index"
          @click="chooseTab(item)"
        >
          <span>{{ item.label }}</span>
        </div>
      </div> -->
    </div>
    <div class="main">
      <div class="main-top">
        <div class="field rank">排名</div>
        <div class="field name">名称</div>
        <div class="field point">明珠值</div>
      </div>
      <div class="main-bottom">
        <div class="list">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :finishedText="finishedText"
            ref="list"
          >
            <div class="item" v-for="(item, index) in listData" :key="index">
              <div class="oItem">
                <div class="oItemRank">
                  <img v-if="index === 0" src="./img/gold.png" alt="" />
                  <img v-else-if="index === 1" src="./img/silver.png" alt="" />
                  <img v-else-if="index === 2" src="./img/copper.png" alt="" />
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="oItemName">
                  <div class="img">
                    <img
                      :src="
                        item.avatar
                          ? item.avatar
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.userName }}</div>
                </div>
                <div class="oItemPoint">
                  <div class="pointNum">{{ item.totalIntegralPoint }}</div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
    <!-- <div class="pointRankList-t">
      <div class="body">
        <div class="rank">
          <div class="rank-t">{{ pointsData.ranking }}</div>
          <div class="rank-b">我的排名</div>
        </div>
        <div class="head">
          <div class="head-l">
            <img
              :src="
                pointsData.avatar
                  ? pointsData.avatar
                  : require('@/assets/img/default_avatar.png')
              "
              alt=""
            />
          </div>
          <div class="head-r">{{ pointsData.userName }}</div>
        </div>
        <div class="point">
          <div class="point-l">{{ pointsData.totlePointCount }}分</div>
          <div class="point-r">
            <img src="./img/point.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="pointRankList-b">
      <div class="body">
        <div class="topRank">
          <div class="item second">
            <div class="item-t">
              <img
                :src="
                  listData[1] && listData[1].avatar
                    ? listData[1].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">2</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[1] && listData[1].userName
                      ? listData[1].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[1] && listData[1].totlePointCount
                    ? listData[1].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item te">
            <div class="item-t">
              <img
                :src="
                  listData[0] && listData[0].avatar
                    ? listData[0].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">1</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[0] && listData[0].userName
                      ? listData[0].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[0] && listData[0].totlePointCount
                    ? listData[0].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
          <div class="item third">
            <div class="item-t">
              <img
                :src="
                  listData[2] && listData[2].avatar
                    ? listData[2].avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="item-c">
              <div class="item-cl">3</div>
              <div class="item-cr">
                <span>
                  {{
                    listData[2] && listData[2].userName
                      ? listData[2].userName
                      : "暂无"
                  }}
                </span>
              </div>
            </div>
            <div class="item-b">
              <span>
                积分:{{
                  listData[2] && listData[2].totlePointCount
                    ? listData[2].totlePointCount
                    : "0"
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="list">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :finishedText="finishedText"
            ref="list"
          >
            <div class="item" v-for="(item, index) in listData" :key="index">
              <div class="oItem" v-if="index != 0 && index != 1 && index != 2">
                <div class="item-l">
                  <div class="indexs">{{ index + 1 }}</div>
                  <div class="img">
                    <img
                      :src="
                        item.avatar
                          ? item.avatar
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.userName }}</div>
                </div>
                <div class="item-r">
                  <div class="pointNum">{{ item.totlePointCount }}分</div>
                  <div class="pointImg">
                    <img src="./img/point.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { pointRankListUrl, pointsUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "pointRankList",
  data() {
    return {
      curTab: {
        label: "个人积分",
        value: "1",
      },
      tabs: [
        {
          label: "个人积分",
          value: "1",
        },
        {
          label: "社群积分",
          value: "2",
        },
      ],
      finishedText: "没有更多了",
      finished: false,
      form: {},
      pointsData: {},
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    onLoad() {
      this.getPointsList();
    },
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.pointsData = res.data;
              if (this.pointsData.avatar) {
                this.pointsData.avatar = this.$handleImg(
                  104,
                  104,
                  this.pointsData.avatar
                );
              }
            }
          } else {
          }
        });
    },
    getPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
        pointType: this.curTab.value,
      };
      this.$axios
        .get(
          `${pointRankListUrl}`,
          { params },
          {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.avatar) {
                item.avatar = this.$handleImg(96, 96, item.avatar);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toList() {
      this.$router.push({
        name: "pointsList",
      });
    },
    chooseTab(item) {
      this.curTab = item;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getPointsList();
    },
  },
};
</script>
<style lang="less" scoped>
.pointRankList {
  min-height: 100%;
  box-sizing: border-box;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 304px;
    z-index: 2;
    > img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .nav {
      position: absolute;
      left: 50%;
      bottom: 18px;
      transform: translateX(-50%);
      width: 552px;
      height: 82px;
      line-height: 82px;
      display: flex;
      font-size: 36px;
      font-weight: bold;
      color: #347eff;
      text-align: center;
      background: #c9e1ff;
      border-radius: 40px;
      .navItem {
        flex: 1;
        border-radius: 40px;
        &.select {
          background: #fff;
        }
      }
    }
  }
  .main {
    padding-top: 304px;
    .main-top {
      display: flex;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
      padding: 32px 0 48px;
      text-align: center;
      .rank {
        flex: 2;
      }
      .name {
        flex: 5;
        text-align: left;
        padding-left: 56px;
      }
      .point {
        flex: 3;
      }
    }
    .main-bottom {
      border-top: 2px solid #fff;
      .list {
        .item {
          .oItem {
            display: flex;
            margin-bottom: 26px;
            align-items: center;
            justify-content: center;
            .oItemRank {
              position: relative;
              flex: 2;
              font-size: 28px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
              text-align: center;
              line-height: 82px;
              > img {
                position: absolute;
                top: 51%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 55px;
                height: 62px;
                vertical-align: middle;
                z-index: -1;
              }
            }
            .oItemName {
              flex: 5;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .img {
                width: 82px;
                height: 82px;
                border: 1px solid rgba(0, 126, 255, 1);
                margin: 0 16px;
                border-radius: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .name {
                width: calc(100% - 120px);
                text-overflow: ellipsis;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 28px;
                color: rgba(102, 102, 102, 1);
              }
            }
            .oItemPoint {
              flex: 3;
              .pointNum {
                text-align: center;
                font-size: 40px;
                font-weight: 600;
                color: #317dff;
                // font-size: 32px;
                // color: rgba(51, 51, 51, 1);
                // margin-right: 8px;
              }
              .pointImg {
                width: 40px;
                height: 40px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  // .pointRankList-t {
  //   background: rgba(201, 227, 255, 1);
  //   position: fixed;
  //   z-index: 999;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 208px;
  //   padding: 32px;
  //   box-sizing: border-box;
  //   .body {
  //     background: url("./img/topBack.png") no-repeat;
  //     background-size: 100% 100%;
  //     padding: 32px 20px;
  //     height: 208px;
  //     box-sizing: border-box;
  //     display: flex;
  //     justify-content: space-around;
  //     color: #fff;
  //     .rank {
  //       text-align: center;
  //       .rank-t {
  //         font-size: 64px;
  //         line-height: 90px;
  //       }
  //       .rank-b {
  //         font-size: 28px;
  //         line-height: 40px;
  //       }
  //     }
  //     .head {
  //       display: flex;
  //       align-items: center;
  //       .head-l {
  //         width: 104px;
  //         height: 104px;
  //         border-radius: 50%;
  //         img {
  //           border-radius: 50%;
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //       .head-r {
  //         width: 260px;
  //         font-size: 32px;
  //         line-height: 44px;
  //         margin-left: 10px;
  //       }
  //     }
  //     .point {
  //       display: flex;
  //       align-items: center;
  //       .point-l {
  //         font-size: 32px;
  //         line-height: 44px;
  //         margin-right: 8px;
  //       }
  //       .point-r {
  //         width: 40px;
  //         height: 40px;
  //         img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  // }
  // .pointRankList-b {
  //   padding: 0 18px;
  //   height: 100%;
  //   .body {
  //     padding: 300px 18px;
  //     background: rgba(241, 248, 255, 1);
  //     overflow: scroll;
  //     height: 100%;
  //     box-sizing: border-box;
  //     .topRank {
  //       display: flex;
  //       height: 280px;
  //       background: #fff;
  //       .item {
  //         flex: 0.333;
  //         padding: 32px 16px 0;
  //         box-sizing: border-box;
  //         background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  //         box-shadow: 0px -10px 12px 0px rgba(104, 122, 15, 0.12);
  //         border-radius: 16px;
  //         .item-t {
  //           width: 104px;
  //           height: 120px;
  //           margin: 0 auto;
  //           text-align: center;
  //           img {
  //             width: 76px;
  //             height: 76px;
  //             border-radius: 50%;
  //             vertical-align: middle;
  //           }
  //           &::after {
  //             content: "";
  //             width: 0;
  //             height: 100%;
  //             display: inline-block;
  //             vertical-align: middle;
  //           }
  //         }
  //         .item-c {
  //           display: flex;
  //           margin: 32px 0 10px;
  //           justify-content: center;
  //           .item-cl {
  //             width: 40px;
  //             height: 40px;
  //             text-align: center;
  //             background: rgba(255, 91, 90, 1);
  //             font-size: 24px;
  //             line-height: 40px;
  //             color: #fff;
  //             border-radius: 50%;
  //             margin-right: -20px;
  //             z-index: 99;
  //           }
  //           .item-cr {
  //             display: flex;
  //             justify-content: center;
  //             align-items: center;
  //             width: 148px;
  //             box-sizing: border-box;
  //             text-align: center;
  //             overflow: hidden;
  //             text-overflow: ellipsis;
  //             white-space: nowrap;
  //             height: 40px;
  //             background: rgba(253, 214, 0, 1);
  //             color: rgba(102, 102, 102, 1);
  //             font-size: 24px;
  //             border-radius: 0 200px 200px 0;
  //             span {
  //               // line-height: 1;
  //               vertical-align: middle;
  //             }
  //           }
  //         }
  //         .item-b {
  //           text-align: center;
  //           font-size: 24px;
  //           color: #666;
  //         }
  //       }
  //       .te {
  //         margin-top: -38px;
  //         z-index: 99;
  //         .item-t {
  //           background: url("./img/one.png") no-repeat;
  //           background-size: 100% 100%;
  //         }
  //       }
  //       .second {
  //         .item-t {
  //           width: 92px;
  //           height: 104px;
  //           background: url("./img/second.png") no-repeat;
  //           background-size: 100% 100%;
  //         }
  //       }
  //       .third {
  //         .item-t {
  //           width: 92px;
  //           height: 104px;
  //           background: url("./img/third.png") no-repeat;
  //           background-size: 100% 100%;
  //         }
  //       }
  //     }
  //     .list {
  //       padding: 0 16px;
  //       .item {
  //         .oItem {
  //           display: flex;
  //           margin-top: 28px;
  //           align-items: center;
  //           justify-content: space-between;
  //           margin-bottom: 64px;
  //           .item-l {
  //             display: flex;
  //             align-items: center;
  //             .indexs {
  //               font-size: 32px;
  //               color: rgba(102, 102, 102, 1);
  //             }
  //             .img {
  //               width: 64px;
  //               height: 64px;
  //               border: 1px solid rgba(0, 126, 255, 1);
  //               margin: 0 16px;
  //               border-radius: 50%;
  //               img {
  //                 width: 100%;
  //                 height: 100%;
  //                 border-radius: 50%;
  //               }
  //             }
  //             .name {
  //               width: 120px;
  //               text-overflow: ellipsis;
  //               overflow: hidden;
  //               text-overflow: ellipsis;
  //               white-space: nowrap;
  //               font-size: 28px;
  //               color: rgba(102, 102, 102, 1);
  //             }
  //           }
  //           .item-r {
  //             display: flex;
  //             align-items: center;
  //             .pointNum {
  //               font-size: 32px;
  //               color: rgba(51, 51, 51, 1);
  //               margin-right: 8px;
  //             }
  //             .pointImg {
  //               width: 40px;
  //               height: 40px;
  //               img {
  //                 width: 100%;
  //                 height: 100%;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
